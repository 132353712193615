'use client';

import { SnackbarProvider } from 'notistack';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import IdlePromptClient from './idle-prompt-client';
import {
  DispensaryTitleBanner,
  FooterNavigation,
  HeaderNavigation,
  LicenseBar,
  SiteWideContextProvider
} from 'ui';
import { LayoutProps } from '@/app/layout';

const queryClient = new QueryClient();

export default function Layout({ children, footer, isPreview }: LayoutProps) {
  return (
    <>
      <SnackbarProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <SiteWideContextProvider
            contextProps={{
              isPreview
            }}>
            <IdlePromptClient />
            <DispensaryTitleBanner />
            <HeaderNavigation isKiosk={true} />
            <main className="layout min-height-kiosk">
              <div>{children}</div>
            </main>
            <LicenseBar />
            {footer ? <FooterNavigation {...footer} /> : null}
          </SiteWideContextProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </>
  );
}
