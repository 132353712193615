import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/curaleaf-kiosk/components/Layout/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/primereact/resources/themes/lara-light-indigo/theme.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/primereact/resources/primereact.min.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/primeicons/primeicons.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/styles/curaleaf.scss");
