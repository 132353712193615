'use client';

import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { useIdleTimer } from 'react-idle-timer';

import { InactivityTimer, useSiteWideContext } from 'ui';

export default function IdleTimerClient() {
  const { push } = useRouter();
  const pathname = usePathname();
  const { clearCart, quantity, selectedDispensary } = useSiteWideContext();
  const isOrderConfirmationPage = pathname.includes('/order-confirmation');

  const ACTIVITY_TIMEOUT_MS = isOrderConfirmationPage ? 1000 * 30 : 1000 * 120;
  const MODAL_DISMISS_TIME_MS = isOrderConfirmationPage ? 1000 * 20 : 1000 * 30;

  const onPrompt = () => {
    // Show prompt only if cart exists or pathname includes /order-confirmation
    if (quantity > 0 || isOrderConfirmationPage) setShowIdlePrompt(true);
  };

  const onActive = () => {
    setShowIdlePrompt(false);
    setRemaining(ACTIVITY_TIMEOUT_MS);
  };

  const onIdle = () => {
    if (selectedDispensary) {
      const cartCookie = Cookies.get('cart_meta');
      if (cartCookie) Cookies.remove('cart_meta');

      // Clear cart when the idle timer expires
      // The !isOrderConfirmationPage is added because the orderConfirmationPage contains code to clear cart
      if (clearCart && quantity > 0 && !isOrderConfirmationPage) clearCart();

      setShowIdlePrompt(false);
      push(selectedDispensary.shopLink);
    }
  };

  const [showIdlePrompt, setShowIdlePrompt] = useState(false);
  const [remaining, setRemaining] = useState(ACTIVITY_TIMEOUT_MS);
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: ACTIVITY_TIMEOUT_MS,
    promptBeforeIdle: MODAL_DISMISS_TIME_MS
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => clearInterval(interval);
  });

  const handleStillHere = () => {
    setShowIdlePrompt(false);
    setRemaining(ACTIVITY_TIMEOUT_MS);
    activate();
  };

  return (
    <>
      {showIdlePrompt ? (
        <InactivityTimer
          inactivePrompt={showIdlePrompt}
          setInactivePrompt={handleStillHere}
          remainingTime={remaining}
          title="Are you still there?"
          button={{
            text: `I'm Still Here`
          }}
        />
      ) : null}
    </>
  );
}
